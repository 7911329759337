import './bootstrap';
// import vue
import Vue from 'vue';

// /**
//  * Next, we will create a fresh Vue application instance and attach it to
//  * the page. Then, you may begin adding components to this application
//  * or customize the JavaScript scaffolding to fit your unique needs.
//  */
// import Gate from './gate';
// Vue.prototype.$gate = new Gate('window.user');

Vue.component('promos', require('./components/Promos.vue').default);
Vue.component('promo', require('./components/Promo.vue').default);

const app = new Vue({
    el: '#app',
});
