<template>
<div class="card">
    <a href="" :id="'btn-collapse-'+index" data-toggle="collapse" :data-target="'#collapse-'+index" :data-id="index"><img class="card-img-top" :src="promo.banner_url"></a>
    <div :id="'collapse-'+index" class="card-body collapse">
        <h6 class="card-title">{{ promo.event_title }}</h6>
        <p class="card-text">
            <component :is="termsConditions(promo) && {template:termsConditions(promo)}"/>
        </p>
    </div>
</div>
</template>

<script>
export default {
    props: ['promo', 'index'],
    data() {
        return {
        }
    },
    methods: {
        termsConditions(promo) {
            return '<div>'+promo.terms_conditions+'</div>';
        },
    },
}
</script>
